<template>
  <v-container fluid grid-list-xl>
    <v-layout>
      <v-flex class="pt-0 pb-2">
        <page-title text="Prelievi"></page-title>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex>
        <form class="mt-4" @submit.prevent="changePage(1)">
          <v-layout>
            <v-flex pt-0 pb-0>
              <v-text-field
                light
                v-model="search"
                prepend-icon="search"
                @click:prepend="getPrelievi"
                append-icon="cancel"
                @click:append="resetItems"
                placeholder="Cerca per codice e titolo prodotto" />
            </v-flex>
            <v-flex xs3>
              <v-select
                class="mt-0"
                label="Tipo"
                :items="tipi"
                item-text="text"
                item-value="val"
                v-model="ricerca.tipo" />
            </v-flex>
          </v-layout>
          <v-layout>
            <v-flex xs3>
              <date-picker
                class="ma-2 ml-4"
                v-model="ricerca.data_da"
                label="Data da"
                name="data_da" />
            </v-flex>
            <v-flex xs3>
              <date-picker
                class="ma-2"
                v-model="ricerca.data_a"
                label="Data a"
                name="data_a" />
            </v-flex>
            <v-flex xs3 class="pa-2">
              <v-btn
                class="ml-0"
                color="primary"
                @click.native="cerca()">
                Filtra
              </v-btn>
              <v-btn
                @click="reset_cerca()">
                Ripristina
              </v-btn>
            </v-flex>
            <v-flex xs3>
            </v-flex>
            </v-layout>
        </form>
        <v-alert
          outline
          color="info"
          value="true"
          v-if="!date.length">
          Nessun prelievo trovato
        </v-alert>
        <v-list class="mt-2" three-line v-if="date.length">
          <v-list-group
            class="listIcon"
            sub-group
            no-action
            v-for="(d, indexd) in date"
            v-model="d.active"
            :key="'tile-d'+indexd">
            <v-list-tile slot="activator">
              <v-list-tile-content class="ml-4">
                <v-list-tile-title>Data {{d.data}}</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
            <v-list class="mt-2" three-line>
              <v-list-group
                class="listIcon"
                sub-group
                no-action
                v-for="(prelievo, indexp) in d.prelievi"
                v-model="prelievo.active"
                :key="'tile-'+prelievo._id">
                <v-list-tile slot="activator">
                  <v-list-tile-content class="ml-4">
                    <v-list-tile-title>
                      <span v-if="prelievo.pedido">Pedido {{prelievo.pedido}} •</span>
                      Tipo: {{tipi[prelievo.tipo].text}}
                      <v-btn color="info" flat small @click="stampa(prelievo)">Stampa</v-btn>
                    </v-list-tile-title>
                  </v-list-tile-content>
                </v-list-tile>
                <v-list-group
                  class="listIcon pl-7 grey--text text--darken-1"
                  sub-group
                  no-action
                  v-for="(bancale, indexb) in prelievo.bancali"
                  v-model="bancale.active"
                  :key="'tile-'+bancale._id">
                  <v-list-tile slot="activator">
                    <div class="chipPosition">
                      <v-chip
                        color="green"
                        text-color="white"
                        v-if="bancale.pos_padre && bancale.pos_padre !== 'TMP'">
                        <span v-if="bancale.sede===1">Cormano-</span>
                        <span v-else>Paderno-</span>
                        {{bancale.pos_padre}}
                      </v-chip>
                      <v-chip
                        color="grey"
                        text-color="white"
                        v-if="bancale.pos_padre && bancale.pos_padre === 'TMP'">
                        <span v-if="bancale.sede===1">Cormano-</span>
                        <span v-else>Paderno-</span>
                        TMP
                      </v-chip>
                    </div>
                    <v-list-tile-title>
                      {{bancale.padre_id}}.{{bancale.progressivo}} - {{bancale.ragione_sociale}}
                    </v-list-tile-title>
                    <v-list-tile-sub-title v-if="bancale.data_ddt">
                      Stato: <strong>Uscito</strong> (DDT del {{bancale.data_ddt}})
                    </v-list-tile-sub-title>
                    <v-list-tile-sub-title v-else>
                      Stato: <strong>In Uscita</strong>
                    </v-list-tile-sub-title>
                    <v-list-tile-sub-title>
                      Qta {{bancale.qta_prelevata}}
                    </v-list-tile-sub-title>
                  </v-list-tile>
                  <v-list-tile v-for="comp in bancale.componenti" :key="comp.componente_id">
                    <v-list-tile-content>
                      <v-list-tile-title class="rowWrapper">
                        <v-layout row wrap class="rowWrapper-row">
                          <v-flex sm12 lg2>
                            <v-text-field label="Opera" v-model="comp.opera" disabled />
                          </v-flex>
                          <v-flex sm12 lg3>
                            <v-text-field label="Descrizione" v-model="comp.descrizione" disabled />
                          </v-flex>
                          <v-flex sm12 lg1>
                            <v-text-field label="Codice" v-model="comp.codice" disabled />
                          </v-flex>
                          <v-flex sm12 lg2>
                            <v-text-field label="Componente" v-model="comp.componente" disabled />
                          </v-flex>
                          <v-flex sm12 lg2>
                            <v-text-field label="Note" v-model="comp.note" disabled />
                          </v-flex>
                          <v-flex sm12 lg1>
                            <v-text-field label="Qtà" v-model="comp.qta" disabled />
                          </v-flex>
                        </v-layout>
                      </v-list-tile-title>
                    </v-list-tile-content>
                  </v-list-tile>
                  <v-divider :key="'divider-'+prelievo._id" v-if="indexb < date.length - 1" inset></v-divider>
                </v-list-group>
                <v-divider :key="'divider-'+prelievo._id" v-if="indexp < date.length - 1" inset></v-divider>
              </v-list-group>
            </v-list>
          </v-list-group>
        </v-list>
        <v-pagination
          class="mt-4"
          v-if="pages > 1"
          :length="pages"
          :value="page"
          @input="changePage($event)" />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { $EventBus } from '@/eventBus'
import PageTitle from '@/components/common/PageTitle'
import datePicker from '@/components/common/DatePicker'

export default {
  data: () => ({
    date: [],
    ricerca: {},
    search: null,
    pages: 0,
    page: 1,
    paginate: 10,
    tipi: [
      { val: 0, text: 'Pedido' },
      { val: 1, text: 'Traslado' },
      { val: 2, text: 'Muestra' },
      { val: 3, text: 'Prelievo' }
    ]
  }),
  components: {
    PageTitle,
    datePicker
  },
  methods: {
    isEmpty (obj) {
      return (obj.keys && obj.keys.length)
    },
    getPrelievi () {
      let o = {
        what: this.search,
        page: this.page,
        rows4page: this.paginate
      }
      if (!this.isEmpty(this.ricerca)) o.ricerca = this.ricerca

      let processData = function (ban) {
        ban.descrizione = null
        ban.more_data = []
        ban.componenti.forEach(function (item) {
          let desc = ''
          if (item.opera) desc += item.opera
          if (item.componente) desc += ' - ' + item.componente
          if (item.descrizione && item.descrizione !== '-') desc += ' - ' + item.descrizione
          desc += ' Qta ' + item.qta
          if (ban.descrizione === null) {
            ban.descrizione = desc
          } else {
            ban.more_data.push({ descrizione: desc })
          }
        })
      }

      this.$plsqlMethod('gestione', 'read_prelievi', o)
        .then(response => {
          if (!response.data.items.length && this.page > 1) {
            this.changePage(this.page - 1)
          } else {
            this.date = response.data.items
            this.pages = response.data.pages

            this.date.forEach(function (d) {
              d.prelievi.forEach(function (pre) {
                pre.bancali.forEach(function (ban) {
                  processData(ban)
                })
              })
            })
          }
        })
        .catch(err => {
          console.log('errore client', err)
          $EventBus.$emit('message', { type: 'error', text: 'Errore' })
        })
    },
    stampa (prelievo) {
      this.$http({
        method: 'post',
        url: `/pdf/prelievo`,
        responseType: 'blob',
        data: prelievo
      }).then((response) => {
        const blob = new Blob([response.data], { type: 'application/pdf' })
        this.$openFileNewTab(blob) // con adblocker picche ocio
      }).catch((err) => {
        console.log(err)
        $EventBus.$emit('message', { type: 'error', text: 'Impossibile creare la stampa dei bancali' })
      })
    },
    resetItems () {
      if (this.search) {
        this.search = null
        this.changePage(1)
      }
    },
    changePage (page) {
      this.page = page
      this.getPrelievi()
    },
    reset_cerca () {
      if (!this.isEmpty(this.ricerca)) {
        this.ricerca = {}
        this.changePage(1)
      }
    },
    cerca () {
      this.getPrelievi()
    }
  },
  mounted () {
    this.getPrelievi()
  }
}
</script>
<style scoped>
  .v-list__group {
    border-bottom: 1px solid rgba(0,0,0,0.12);
  }
  .rowWrapper {
    height: auto;
    line-height: inherit;
    padding: 10px 0;
  }
  .rowWrapper-row {
    height: 90px;
    margin-top: 0!important;
  }
  .underline--text {
    text-decoration: underline;
  }
  .chipPosition {
    min-width: 150px;
    text-align: left;
  }
  .pl-7 {
    padding-left: 70px;
  }
</style>
