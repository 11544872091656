<template>
  <v-menu
    :ref="myMenu"
    :close-on-content-click="false"
    v-model="myMenu"
    :nudge-right="40"
    lazy
    transition="scale-transition"
    offset-y
    full-width
    max-width="290px"
    min-width="290px">
    <v-text-field
      slot="activator"
      v-model="formattedValue"
      @focus="focus()"
      :name="name"
      :label="label"
      :rules="required ? [$rules.required] : []"
      prepend-inner-icon="event"
      :clearable="!readonly"
      :disabled="readonly"
      :readonly="readonly" />
    <v-date-picker
      v-model="myDate"
      no-title
      :readonly="!!readonly"
      @input="myMenu = false"
      :nudge-right="40"
      :first-day-of-week="1"
      :max="max"
      locale="it-it"
      offset-y
      scrollable />
  </v-menu>
</template>
<script>
export default {
  props: {
    value: {
      type: String
    },
    label: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    readonly: {
      type: Boolean,
      required: false
    },
    required: {
      type: Boolean
    },
    max: {
      type: String,
      required: false,
      default: '2118-12-31'
    }
  },
  data () {
    return {
      myMenu: false,
      myDate: null,
      formattedValue: null
    }
  },
  methods: {
    formatDate (date) {
      if (!date) return null

      const [year, month, day] = date.split('-')
      return `${day}/${month}/${year}`
    },
    parseDate (date) {
      if (!date) return null

      const [day, month, year] = date.split('/')
      return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
    },
    focus () {
      this.myDate = this.parseDate(this.formattedValue)
    }
  },
  watch: {
    value: function (val) {
      this.formattedValue = val
    },
    myDate (val) {
      this.formattedValue = this.formatDate(val)
      this.$emit('input', this.formattedValue)
    }
  },
  mounted () { // Needed for dynamically generated fields (eg. in v-for)
    this.formattedValue = this.value
  }
}
</script>
