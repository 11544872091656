<template>
  <h1 id="titolo" class="display-1 mb-4 primary--text">{{ text }}</h1>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    }
  }
}
</script>
